import React, { useEffect, useRef } from 'react';
import { useGlobal } from 'reactn';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import gql from 'graphql-tag';
import { ApolloProvider } from '@apollo/react-hooks';
import { useAuth } from './auth/AuthProvider';
// import { WebSocketLink } from "apollo-link-ws";
// import { SubscriptionClient } from "subscriptions-transport-ws";
// we'll need to migrate away from apollo boost in order to use subscriptions/websockets
// https://www.apollographql.com/docs/react/migrating/boost-migration/
// query structure docs
// https://github.com/SimonCropp/GraphQL.EntityFramework/blob/main/docs/query-usage.md

const GET_ME = gql`
  query {
    me {
      canLogIn
      email
      firstName
      id
      lastName
      role
      roles
      isSubcontractor
      independentContractorId
      groupUsers {
        group {
          id
          name
        }
        isPrimaryContact
      }
      claims {
        claimValue
      }
    }
  }
`;

export const ClientProvider = ({ children }) => {
  const [showReload, setShowReload] = useGlobal('showReload');
  const auth = useAuth();
  const headers = useRef({});
  const client = useRef(
    new ApolloClient({
      link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
              console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
            );
          if (networkError) console.log(`[Network error]: ${networkError}`);

          // if we're not authenticated, sign user out (although it may be that we're not authorised for this route)
          if (networkError && networkError.statusCode === 401) {
            console.error('getting 401');
          }

          if (networkError && networkError.statusCode === 403) {
            console.error('getting 403');
            // in theory we're authed, but think token needs refreshing?
          }
        }),
        new HttpLink({
          uri: '/graphql',
          headers: headers.current,
          credentials: 'same-origin',
          request: (operation) => {
            operation.setContext({
              headers: {
                authorization: headers.current.Authorization ? headers.current.Authorization : ''
              }
            });
          }
        })
      ]),
      cache: new InMemoryCache({ addTypename: false }),
      defaultOptions: { query: { fetchPolicy: 'no-cache' } }
    })
  );

  useEffect(() => {
    if (auth.token != null && !auth.isImpersonating && !auth.loading) {
      // in theory, no longer need this. Cookie will be set. But initial request needs it.
      headers.current.Authorization = `Bearer ${auth.token}`;

      // here's where we set auth.me. I think sometimes, the token above is stale?
      client.current
        .query({ query: GET_ME })
        .then((result) => {
          const me = result.data.me[0];
          // Flatten claims so they are easier to check
          me.claims = me.claims.map((c) => c.claimValue);
          auth.updateMe(me);
          headers.current.Authorization = ''; // dont need this anymore as we've got the cookie
        })
        .catch((err) => {
          auth.setMeFailed(true);
          console.error(err);
        });
    }
  }, [auth.token, auth.isImpersonating, auth.loading]);

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};
