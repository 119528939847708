import React, { useEffect, useState, useRef, useCallback } from 'react';
import { db } from '../../utils/db';
import { useLiveQuery } from 'dexie-react-hooks';
import { useAuth } from '../../auth/AuthProvider';
import moment from 'moment';

const Sync = ({ hidden, constructionId }) => {
  const auth = useAuth();
  const progress = useRef(false);

  const [openPhotoHistory, setOpenPhotoHistory] = useState(false);

  const syncFirst = useCallback(async () => {
    const photos = await db.photos.limit(1).toArray();

    if (photos.length == 1) {
      const photo = photos[0];

      if (photo.status == 'uploaded') {
        await db.photos.delete(photo.id);
        db.photoHistory.update(photo.id, { isCleanedUp: true, lastUpdated: new Date().toISOString().split('.')[0] });
      }

      let redoGetUrl = false;
      if (photo.status === 'getUrl') {
        const previousGetUrl = localStorage.getItem('sync-photo-last-getUrl');
        const previousGetUrlMs = parseInt(previousGetUrl);
        if (!isNaN(previousGetUrlMs)) {
          redoGetUrl = new Date().getTime() - previousGetUrlMs > 60 * 1000;
        } else {
          redoGetUrl = true;
        }
      }

      if (photo.status === undefined || photo.status == 'pending' || (photo.status === 'getUrl' && redoGetUrl)) {
        await db.photos.update(photo.id, { status: 'getUrl' });
        if (!redoGetUrl) {
          db.photoHistory.update(photo.id, { getUrl: true, lastUpdated: new Date().toISOString().split('.')[0] });
        } else {
          db.photoHistory.update(photo.id, { redoGetUrl: true, lastUpdated: new Date().toISOString().split('.')[0] });
        }

        localStorage.setItem('sync-photo-last-getUrl', new Date().getTime().toString());

        try {
          const params = await getPresignedUploadParams({
            name: photo.name,
            size: photo.size,
            tags: photo.tags,
            comments: photo.description,
            id: photo.constructionId,
            potentialJobId: photo.potentialJobId
          });

          console.log(params);

          if (params.uploadUrl === undefined) {
            throw new Error('Upload URL is undefined');
          }

          await db.photos.update(photo.id, {
            url: params.uploadUrl,
            status: 'uploading'
          });
          db.photoHistory.update(photo.id, {
            getUploadParams: true,
            attachmentId: params.attachment.id,
            attachmentUrl: params.attachment.url,
            lastUpdated: new Date().toISOString().split('.')[0]
          });
        } catch (err) {
          // if there's an issue getting upload params, then we'll just try again later
          await db.photos.update(photo.id, { status: 'pending' });
          db.photoHistory.update(photo.id, {
            getUploadParamsError: true,
            lastUpdated: new Date().toISOString().split('.')[0]
          });
          return;
        }
      }

      if (photo.status === 'uploading' || photo.status == 'retrying') {
        try {
          const response = await uploadAttachment(photo.url, photo.blob);
          await db.photos.update(photo.id, { status: 'uploaded' });
          db.photoHistory.update(photo.id, {
            uploadAttachment: true,
            lastUpdated: new Date().toISOString().split('.')[0]
          });
        } catch (err) {
          console.error(err);
          await db.photos.update(photo.id, { status: 'retrying' });
          db.photoHistory.update(photo.id, {
            uploadAttachmentError: true,
            lastUpdated: new Date().toISOString().split('.')[0]
          });
          return;
        }
      }

      await syncFirst(); // queue the next image immediately
    }
  }, []);

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  const uploadAttachment = (url, imageData) => {
    const imageBlob = dataURItoBlob(imageData);

    let contentType = 'image/png';
    if (url.indexOf('.jpg') > -1) {
      contentType = 'image/jpeg';
    }

    return fetch(url, {
      method: 'PUT',
      body: imageBlob,
      headers: { 'Content-Type': contentType }
    });
  };

  const getPresignedUploadParams = ({ name, size, tags, comments, id, potentialJobId }) => {
    auth.getToken();

    return fetch('/api/attachments/getPresignedUploadParams', {
      method: 'POST',
      body: JSON.stringify({
        name,
        sizeBytes: size,
        constructionId: id,
        potentialJobId: potentialJobId,
        tags: tags,
        comments: comments
      }),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${auth.token}`
      }
    }).then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw new Error(res.status);
      }
    });
  };

  const photos = useLiveQuery(() => db.photos.toArray()) || [];
  const photoHistory = useLiveQuery(() => db.photoHistory.toArray())?.sort((a, b) => b.id - a.id) || [];
  useEffect(() => {
    const interval = setInterval(async () => {
      // otherwise, retry every 5 seconds
      if (!progress.current) {
        progress.current = true;
        try {
          await syncFirst();
        } catch (exc) {
        } finally {
          progress.current = false;
        }
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [photos]);

  var color = 'success';
  var message = 'All files are up to date';

  if (photos?.length > 0) {
    color = 'warning';
    message = `${photos.length} files need to be synced`;
  }

  const formatDateTime = (dateTime) => {
    if (!dateTime) return null;
    const formattedDateTime = moment.utc(dateTime).tz(moment.tz.guess()).format('DD MM YYYY hh:mm A');
    return formattedDateTime;
  };

  if (constructionId) {
    const oneHourAgo = moment().subtract(1, 'hours').toISOString().split('.')[0];

    // show image previews here, 80x80 images in a grid

    return (
      <>
        {photoHistory && photoHistory.length > 0 && (
          <table className="table">
            <tbody>
              {photoHistory
                .filter(
                  (p) =>
                    (p.constructionId == constructionId || p.potentialJobId == constructionId) &&
                    p.lastUpdated > oneHourAgo
                )
                .map((item, index) => {
                  let photo = photos?.find((p) => p.id == item.id);

                  return (
                    <tr className={photo ? 'table-warning' : 'table-success'} key={index}>
                      <td className="p-0">
                        <div
                          style={{
                            display: 'inline-block',
                            width: '80px',
                            height: '80px',
                            margin: '5px',
                            backgroundColor: '#000000',
                            backgroundImage: `url(${photo ? photo.blob : item.attachmentUrl})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center'
                          }}
                        ></div>
                      </td>
                      <td>
                        <strong>{item.name}</strong>{' '}
                        {photo && photo.status && (
                          <span className="badge badge-warning">{photo.status || 'pending'}</span>
                        )}
                        {item.description && (
                          <span>
                            <br />
                            {item.description}
                          </span>
                        )}
                        <br />
                        {item.size} bytes
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </>
    );
  }

  if (hidden) {
    return null;
  }

  return (
    <div className={'alert py-2 alert-' + color} id="fileSync">
      <div style={{ display: 'flex' }}>
        <div className="pt-1">{message}</div>
        <div style={{ marginLeft: 'auto' }}>
          <button
            className="btn btn-secondary mr-1"
            style={{ padding: '3px 10px' }}
            onClick={() => setOpenPhotoHistory(!openPhotoHistory)}
          >
            {openPhotoHistory ? 'Close Photo History' : 'Photo History'}
          </button>
        </div>
      </div>

      {openPhotoHistory && (
        <div>
          <div>Photo History</div>
          {photoHistory && (
            <div style={{ overflow: 'auto' }}>
              <table className="table table-sm table-borderless table-hover mt-3">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>name</th>
                    <th>constructionId</th>
                    <th>potentialJobId</th>
                    <th>size</th>
                    <th>isCleanedUp</th>
                    <th>getUrl</th>
                    <th>redoGetUrl</th>
                    <th>uploadAttachment</th>
                    <th>uploadAttachmentError</th>
                    <th>getUploadParams</th>
                    <th>getUploadParamsError</th>
                    <th>createdDate</th>
                    <th>lastUpdated</th>
                  </tr>
                </thead>
                <tbody>
                  {photoHistory.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className={item.uploadAttachmentError || item.getUploadParamsError ? 'table-danger' : ''}
                      >
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.constructionId}</td>
                        <td>{item.potentialJobId}</td>
                        <td>{item.size}</td>
                        <td>{item.isCleanedUp ? 'True' : '-'}</td>
                        <td>{item.getUrl ? 'True' : '-'}</td>
                        <td>{item.redoGetUrl ? 'True' : '-'}</td>
                        <td>{item.uploadAttachment ? 'True' : '-'}</td>
                        <td>{item.uploadAttachmentError ? 'True' : '-'}</td>
                        <td>{item.getUploadParams ? 'True' : '-'}</td>
                        <td>{item.getUploadParamsError ? 'True' : '-'}</td>
                        <td>{formatDateTime(item.createdDate)}</td>
                        <td>{formatDateTime(item.lastUpdated)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {photos?.length > 0 && !openPhotoHistory && (
        <table className="table table-sm table-borderless mt-3">
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Name</th>
              <th style={{ width: '25%' }}>Tags</th>
              <th style={{ width: '25%' }}>Size</th>
              <th style={{ width: '25%' }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {photos?.map((photo, index) => {
              return (
                <tr key={index}>
                  <td>{photo.name}</td>
                  <td>{photo.tags.join(',')}</td>
                  <td>{photo.size}</td>
                  <td>{photo.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Sync;
