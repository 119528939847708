import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Table } from 'reactstrap';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DraftBuilderApprovalMessage from '../BuilderApprovals/DraftBuilderApprovalMessage';
import { getAddress, getAddressRange } from '../../utils/utilFunctions';
import { convertPhoneNumber, getIsValidPhoneNumber } from '../BuilderApprovals/BuilderUtils';
import Capture from './Capture2';
import { generateBuilderSlug, generateBuilderMessage } from '../BuilderApprovals/BuilderSlug';

const GET_REFERENCE_DATA = gql`
  query GetReferenceData {
    builderLookup
    jobStatuses: systemSettings(where: { path: "Name", comparison: equal, value: "Job Status Settings" }) {
      id
      name
      workType
      config
    }
    addressTypeSettings: systemSettings(where: { path: "Name", comparison: equal, value: "Address Type" }) {
      id
      name
      workType
      config
    }
  }
`;

const UPDATE_POTENTIAL_JOB = gql`
  mutation UpdatePotentialJob($potentialJob: PotentialJobInput!, $processedFromOffice: Boolean) {
    updatePotentialJob(potentialJob: $potentialJob, processedFromOffice: $processedFromOffice) {
      id
      builder
      siteContactName
      siteContactPhone
      status
      lotNumber
      unitNumber
      streetNumber
      streetName
      roadType
      suburb
      state
      postCode
      assignedFieldUserId
      addressFromField
      needsOfficeApproval
    }
  }
`;

const SEND_POTENTIAL_JOB_REQUEST = gql`
  mutation SendRequest($ids: [Int]!, $message: String!) {
    sendPotentialJobRequest(ids: $ids, message: $message)
  }
`;

const FormField = ({ label, children }) => {
  return (
    <Row className="pb-1">
      <Col md="12" lg="3" className="pt-1">
        <label className="mb-0">{label}</label>
      </Col>
      <Col md="12" lg="9">
        {children}
      </Col>
    </Row>
  );
};

const NewPotentialJob = ({
  showPotentialJobModal,
  setShowPotentialJobModal,
  jobData,
  onJobUpdated,
  fullAddressOnly,
  attachments
}) => {
  const [builderList, setBuilderList] = useState(null);
  //const [statusList, setStatusList] = useState([]);
  const [selectedBuilder, setSelectedBuilder] = useState(!!jobData ? jobData.builder : '');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [showBuilderApproval, setShowBuilderApproval] = useState(false);
  const [selectedJobsData, setSelectedJobsData] = useState(null);
  const [showFullAddress, setShowFullAddress] = useState(!!jobData || fullAddressOnly);
  const [showPhotoCapture, setShowPhotoCapture] = useState(false);
  const { assignedFieldUser, potentialJobComments, ...filteredJobData } = jobData
    ? { ...jobData, needsOfficeApproval: false }
    : {};

  const [newPotentialJob, setNewPotentialJob] = useState(
    jobData
      ? filteredJobData
      : {
          builder: '',
          siteContactName: '',
          siteContactPhone: '',
          status: '',
          addressFromField: '',
          lotNumber: '',
          lotRangeLow: null,
          lotRangeHigh: null,
          unitNumber: '',
          unitRangeLow: null,
          unitRangeHigh: null,
          streetNumber: '',
          streetRangeLow: null,
          streetRangeHigh: null,
          streetName: '',
          roadType: '',
          suburb: '',
          state: '',
          postCode: '',
          comments: '',
          needsOfficeApproval: fullAddressOnly ? false : true
        }
  );

  const { data } = useQuery(GET_REFERENCE_DATA);

  const [updatePotentialJob] = useMutation(UPDATE_POTENTIAL_JOB, {
    onCompleted: (data) => {
      if (data.updatePotentialJob && data.updatePotentialJob[0]) {
        toast.success('Potential Job added successfully');
        setIsValidPhoneNumber(getIsValidPhoneNumber(convertPhoneNumber(data.updatePotentialJob[0].siteContactPhone)));
        const _selectedJobsData = getSelectedJobsData(data.updatePotentialJob);
        setSelectedJobsData(_selectedJobsData);
        if (onJobUpdated) {
          onJobUpdated(data.updatePotentialJob);
        }
      }
    },
    onError: (error) => {
      toast.error('Error updating job: ' + error.message);
    }
  });

  const [sendRequestToBuilder] = useMutation(SEND_POTENTIAL_JOB_REQUEST, {
    onCompleted: (data) => {
      if (data.sendPotentialJobRequest) {
        toast.success('Request sent successfully');
        setShowPotentialJobModal(false);
      }
    },
    onError: (error) => {
      toast.error('Error sending request: ' + error.message);
    }
  });

  useEffect(() => {
    if (!data) return;
    setBuilderList(data.builderLookup);

    if (data.addressTypeSettings) {
      const addressTypeSettings = data.addressTypeSettings.map((setting) => ({
        workType: setting.workType,
        values: JSON.parse(setting.config).addressSetting
      }));

      let bookableWorkTypes = [];

      addressTypeSettings.forEach((setting) => {
        if (setting.values?.bookable) {
          bookableWorkTypes.push(setting.workType);
        }
      });

      // if (data.jobStatuses) {
      //   const jobStatuses = data.jobStatuses.map((setting) => ({
      //     workType: setting.workType,
      //     statuses: JSON.parse(setting.config).statuses
      //   }));

      //   let statuses = [];

      //   jobStatuses.forEach((setting) => {
      //     if (bookableWorkTypes.includes(setting.workType)) {
      //       if (setting.statuses) {
      //         setting.statuses.forEach((status) => {
      //           if (status.builderApproval) {
      //             statuses.push(status.statusName);
      //           }
      //         });
      //       }
      //     }
      //   });

      //   statuses = [...new Set(statuses)];

      //   setStatusList(
      //     statuses.map((status) => ({
      //       value: status,
      //       label: status
      //     }))
      //   );
      // }
    }
  }, [data]);

  useEffect(() => {
    setNewPotentialJob((prev) => ({
      ...prev,
      builder: selectedBuilder
    }));
  }, [selectedBuilder]);

  const handleSave = async () => {
    const result = await updatePotentialJob({
      variables: {
        potentialJob: newPotentialJob,
        processedFromOffice: !!jobData
      }
    });

    if (result.data?.updatePotentialJob?.[0]) {
      // Store the first job's ID since that's where we'll attach photos
      setNewPotentialJob(result.data.updatePotentialJob[0]);
    }
  };

  const handleSaveAndPhoto = async () => {
    const result = await updatePotentialJob({
      variables: {
        potentialJob: newPotentialJob,
        processedFromOffice: !!jobData
      }
    });

    if (result.data?.updatePotentialJob?.[0]) {
      // Store the first job's ID since that's where we'll attach photos
      setNewPotentialJob(result.data.updatePotentialJob[0]);
      // Now show the photo capture modal
      setShowPhotoCapture(true);
    }
  };

  const handleCloseApproval = () => {
    setShowBuilderApproval(false);
    setSelectedJobsData(null);
    setShowPotentialJobModal(false);
  };

  const getSelectedJobsData = (createdPotentialJobs) => {
    const fullAddress = getAddressRange(createdPotentialJobs);
    const potentialJobIds = createdPotentialJobs.map((job) => job.id);
    if (!showFullAddress) {
      createdPotentialJobs.forEach((job) => {
        job.address = job.needsOfficeApproval ? job.addressFromField : getAddress(job);
        job.selected = true;
      });
    }

    const { link, domainName } = generateBuilderSlug(
      createdPotentialJobs[0].siteContactName,
      createdPotentialJobs[0].siteContactPhone,
      potentialJobIds
    );

    return {
      siteContact: createdPotentialJobs[0].siteContactName,
      phoneNumber: createdPotentialJobs[0].siteContactPhone,
      jobs: createdPotentialJobs,
      addressText: fullAddress,
      message: generateBuilderMessage(fullAddress, link, domainName)
    };
  };

  const requestSent = () => {
    const potentialJobIds = selectedJobsData.jobs.map((job) => job.id);
    const message = selectedJobsData.message.replace(/\n/g, ' ');
    sendRequestToBuilder({ variables: { ids: potentialJobIds, message: message } });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('Range')) {
      setNewPotentialJob({ ...newPotentialJob, [name]: value ? Number(value) : null });
    } else {
      setNewPotentialJob({ ...newPotentialJob, [name]: value });
    }
  };

  // const testJobs = [
  //   {
  //     lotNumber: 12,
  //     unitNumber: 101,
  //     streetNumber: 100,
  //     streetName: 'Main St',
  //     roadType: 'Rd',
  //     suburb: 'Suburbia',
  //     state: 'State',
  //     postCode: '12345'
  //   },
  //   {
  //     lotNumber: 12,
  //     unitNumber: 102,
  //     streetNumber: 100,
  //     streetName: 'Main St',
  //     roadType: 'Rd',
  //     suburb: 'Suburbia',
  //     state: 'State',
  //     postCode: '12345'
  //   }
  // ];

  // const testJobs = [
  //   { lotNumber: 12, streetName: 'Main St', roadType: 'Rd', suburb: 'Suburbia', state: 'State', postCode: '12345' },
  //   { lotNumber: 13, streetName: 'Main St', roadType: 'Rd', suburb: 'Suburbia', state: 'State', postCode: '12345' }
  // ];

  //console.log('getAddressRange', getAddressRange(testJobs));

  return (
    <>
      <Modal isOpen={showPotentialJobModal} toggle={() => setShowPotentialJobModal(!showPotentialJobModal)} size="lg">
        <ModalHeader>{!!jobData ? 'Process Potential Job' : 'New Potential Job'}</ModalHeader>
        {!selectedJobsData && (
          <ModalBody style={{ backgroundColor: '#EBECED' }}>
            <form>
              {!!jobData && (
                <div className="pb-1 font-weight-bolder">
                  <FormField label="Address From Field">
                    <div>{newPotentialJob.addressFromField}</div>
                  </FormField>
                </div>
              )}
              <FormField label="Builder">
                {builderList !== null && (
                  <BuilderSelect
                    builderList={builderList}
                    selectedBuilder={selectedBuilder}
                    setSelectedBuilder={setSelectedBuilder}
                  />
                )}
              </FormField>
              <FormField label="Site Contact Name">
                <input
                  type="text"
                  name="siteContactName"
                  value={newPotentialJob.siteContactName}
                  onChange={handleChange}
                  className="form-control"
                />
              </FormField>
              <FormField label="Site Contact Phone">
                <input
                  type="text"
                  name="siteContactPhone"
                  value={newPotentialJob.siteContactPhone}
                  onChange={handleChange}
                  className="form-control"
                />
              </FormField>
              {/* <FormField label="Status">
                <Select
                  options={statusList}
                  value={statusList.find((status) => status.value === potentialJob.status)}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setPotentialJob({ ...potentialJob, status: selectedOption.value });
                    } else {
                      setPotentialJob({ ...potentialJob, status: '' });
                    }
                  }}
                  isClearable
                  placeholder="Select a status"
                  classNamePrefix="react-select"
                />
              </FormField> */}
              {!showFullAddress && (
                <FormField label="Address">
                  <input
                    type="text"
                    name="addressFromField"
                    value={newPotentialJob.addressFromField}
                    onChange={handleChange}
                    className="form-control"
                  />
                </FormField>
              )}
              {showFullAddress && (
                <>
                  <div className="pt-3">
                    <FormField label="Lot Number">
                      <input
                        type="text"
                        name="lotNumber"
                        value={newPotentialJob.lotNumber}
                        onChange={handleChange}
                        className="form-control"
                        disabled={!!newPotentialJob.lotRangeLow || !!newPotentialJob.lotRangeHigh}
                      />
                    </FormField>
                  </div>

                  <FormField label="Lot Range">
                    <div className="form-group d-flex align-items-center">
                      <input
                        type="number"
                        name="lotRangeLow"
                        defaultValue={newPotentialJob.lotRangeLow}
                        onChange={handleChange}
                        className="form-control mr-2"
                        style={{ maxWidth: '100px' }}
                        disabled={
                          !!newPotentialJob.lotNumber ||
                          !!newPotentialJob.unitRangeLow ||
                          !!newPotentialJob.unitRangeHigh ||
                          !!newPotentialJob.streetRangeLow ||
                          !!newPotentialJob.streetRangeHigh
                        }
                      />
                      <span className="mr-2">-</span>
                      <input
                        type="number"
                        name="lotRangeHigh"
                        defaultValue={newPotentialJob.lotRangeHigh}
                        onChange={handleChange}
                        className="form-control"
                        style={{ maxWidth: '100px' }}
                        disabled={
                          !!newPotentialJob.lotNumber ||
                          !!newPotentialJob.unitRangeLow ||
                          !!newPotentialJob.unitRangeHigh ||
                          !!newPotentialJob.streetRangeLow ||
                          !!newPotentialJob.streetRangeHigh
                        }
                      />
                    </div>
                  </FormField>
                  <FormField label="Unit Number">
                    <input
                      type="text"
                      name="unitNumber"
                      value={newPotentialJob.unitNumber}
                      onChange={handleChange}
                      className="form-control"
                      disabled={!!newPotentialJob.unitRangeLow || !!newPotentialJob.unitRangeHigh}
                    />
                  </FormField>
                  <FormField label="Unit Range">
                    <div className="form-group d-flex align-items-center">
                      <input
                        type="number"
                        name="unitRangeLow"
                        defaultValue={newPotentialJob.unitRangeLow}
                        onChange={handleChange}
                        className="form-control mr-2"
                        style={{ maxWidth: '100px' }}
                        disabled={
                          !!newPotentialJob.unitNumber ||
                          !!newPotentialJob.lotRangeLow ||
                          !!newPotentialJob.lotRangeHigh ||
                          !!newPotentialJob.streetRangeLow ||
                          !!newPotentialJob.streetRangeHigh
                        }
                      />
                      <span className="mr-2">-</span>
                      <input
                        type="number"
                        name="unitRangeHigh"
                        defaultValue={newPotentialJob.unitRangeHigh}
                        onChange={handleChange}
                        className="form-control"
                        style={{ maxWidth: '100px' }}
                        disabled={
                          !!newPotentialJob.unitNumber ||
                          !!newPotentialJob.lotRangeLow ||
                          !!newPotentialJob.lotRangeHigh ||
                          !!newPotentialJob.streetRangeLow ||
                          !!newPotentialJob.streetRangeHigh
                        }
                      />
                    </div>
                  </FormField>
                  <FormField label="Street Number">
                    <input
                      type="text"
                      name="streetNumber"
                      value={newPotentialJob.streetNumber}
                      onChange={handleChange}
                      className="form-control"
                      disabled={!!newPotentialJob.streetRangeLow || !!newPotentialJob.streetRangeHigh}
                    />
                  </FormField>
                  <FormField label="Street Range">
                    <div className="form-group d-flex align-items-center">
                      <input
                        type="number"
                        name="streetRangeLow"
                        defaultValue={newPotentialJob.streetRangeLow}
                        onChange={handleChange}
                        className="form-control mr-2"
                        style={{ maxWidth: '100px' }}
                        disabled={
                          !!newPotentialJob.streetNumber ||
                          !!newPotentialJob.lotRangeLow ||
                          !!newPotentialJob.lotRangeHigh ||
                          !!newPotentialJob.unitRangeLow ||
                          !!newPotentialJob.unitRangeHigh
                        }
                      />
                      <span className="mr-2">-</span>
                      <input
                        type="number"
                        name="streetRangeHigh"
                        defaultValue={newPotentialJob.streetRangeHigh}
                        onChange={handleChange}
                        className="form-control"
                        style={{ maxWidth: '100px' }}
                        disabled={
                          !!newPotentialJob.streetNumber ||
                          !!newPotentialJob.lotRangeLow ||
                          !!newPotentialJob.lotRangeHigh ||
                          !!newPotentialJob.unitRangeLow ||
                          !!newPotentialJob.unitRangeHigh
                        }
                      />
                    </div>
                  </FormField>
                  <FormField label="Street Name">
                    <input
                      type="text"
                      name="streetName"
                      value={newPotentialJob.streetName}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </FormField>
                  <FormField label="Road Type">
                    <input
                      type="text"
                      name="roadType"
                      value={newPotentialJob.roadType}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </FormField>
                  <FormField label="Suburb">
                    <input
                      type="text"
                      name="suburb"
                      value={newPotentialJob.suburb}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </FormField>
                  <FormField label="State">
                    <input
                      type="text"
                      name="state"
                      value={newPotentialJob.state}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </FormField>
                  <FormField label="Post Code">
                    <input
                      type="text"
                      name="postCode"
                      value={newPotentialJob.postCode}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </FormField>
                </>
              )}
              <FormField label="Comments">
                <input
                  type="text"
                  name="comments"
                  value={newPotentialJob.comments}
                  onChange={handleChange}
                  className="form-control"
                />
              </FormField>

              {attachments && attachments.length > 0 && (
                <FormField label="Attachments">
                  <div>
                    {attachments.map((attachment, index) => (
                      <div key={attachment.id} className="mb-1">
                        <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                          {attachment.title || `Attachment ${index + 1}`}
                        </a>
                      </div>
                    ))}
                  </div>
                </FormField>
              )}
            </form>
          </ModalBody>
        )}
        {!!selectedJobsData && (
          <ModalBody>
            <Table responsive>
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Builder</th>
                  <th>Site Contact</th>
                </tr>
              </thead>
              <tbody>
                {selectedJobsData.jobs.map((job, index) => (
                  <tr key={index}>
                    <td>{job.needsOfficeApproval ? job.addressFromField : getAddress(job)}</td>
                    <td>{job.builder}</td>
                    <td>{(job.siteContactName ?? '') + ' ' + (job.siteContactPhone ?? '')}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
        )}
        <ModalFooter>
          <div className="d-flex justify-content-between w-100 mt-1 align-items-center flex-wrap">
            <div>
              {!selectedJobsData && !fullAddressOnly && !jobData && (
                <Button
                  className="mr-2"
                  color="primary"
                  onClick={() => {
                    const showFullAddressNewValue = !showFullAddress;
                    setShowFullAddress(showFullAddressNewValue);
                    setNewPotentialJob({ ...newPotentialJob, needsOfficeApproval: !showFullAddressNewValue });
                  }}
                >
                  {showFullAddress ? 'Hide Detailed Address Fields' : 'Show Detailed Address Fields'}
                </Button>
              )}
            </div>
            <div className="d-flex">
              <Button
                color="secondary"
                className="mr-2"
                onClick={() => setShowPotentialJobModal(!showPotentialJobModal)}
              >
                {selectedJobsData ? 'Done' : 'Cancel'}
              </Button>
              {!selectedJobsData && (
                <>
                  {!jobData && !showFullAddress && (
                    <Button
                      disabled={
                        (!newPotentialJob.streetName || !newPotentialJob.suburb) &&
                        (!newPotentialJob.addressFromField || !!jobData)
                      }
                      color="success"
                      className="mr-2"
                      onClick={handleSaveAndPhoto}
                    >
                      Take Photo
                    </Button>
                  )}
                  <Button
                    color="primary"
                    disabled={
                      (!newPotentialJob.streetName || !newPotentialJob.suburb) &&
                      (!newPotentialJob.addressFromField || !!jobData)
                    }
                    title={
                      !newPotentialJob.streetName || !newPotentialJob.suburb ? 'Please fill in the required fields' : ''
                    }
                    onClick={handleSave}
                  >
                    {!!jobData ? 'Submit' : 'Save'}
                  </Button>
                </>
              )}
              {selectedJobsData && isValidPhoneNumber && !jobData && !fullAddressOnly && (
                <Button color="primary" onClick={() => setShowBuilderApproval(true)}>
                  Draft Builder Approval Message
                </Button>
              )}
            </div>
          </div>
        </ModalFooter>
      </Modal>

      {showPhotoCapture && (
        <Modal
          isOpen={true}
          toggle={() => setShowPhotoCapture(false)}
          size="xl"
          className="modal-fullscreen" // Add this class
          style={{
            maxWidth: '100%',
            margin: '0',
            height: '100vh',
            width: '100vw'
          }} // Add these styles
        >
          <ModalHeader toggle={() => setShowPhotoCapture(false)}>Take Photo</ModalHeader>
          <ModalBody className="p-0" style={{ height: 'calc(100vh - 135px)' }}>
            {' '}
            {/* Add height style */}
            <Capture
              isPotentialJob={true}
              potentialJobData={newPotentialJob}
              onPhotoTaken={() => setShowPhotoCapture(false)}
            />
          </ModalBody>
        </Modal>
      )}

      {showBuilderApproval && selectedJobsData && (
        <DraftBuilderApprovalMessage
          selectedJobsData={selectedJobsData}
          requestSent={requestSent}
          handleCloseApproval={handleCloseApproval}
        />
      )}
    </>
  );
};

export default NewPotentialJob;

const BuilderSelect = ({ builderList, selectedBuilder, setSelectedBuilder }) => {
  const [options, setOptions] = useState(
    builderList.map((builder) => ({
      label: builder,
      value: builder
    }))
  );

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedBuilder(selectedOption.value);
    } else {
      setSelectedBuilder('');
    }
  };

  const handleInputChange = (inputValue, { action }) => {
    if (action === 'input-change') {
      setSelectedBuilder(inputValue);
    }
  };

  const handleBlur = () => {
    if (selectedBuilder && !options.find((option) => option.value === selectedBuilder)) {
      const newOption = { label: selectedBuilder, value: selectedBuilder };
      setOptions([...options, newOption]);
    }
  };

  return (
    <div style={{ width: '260px' }}>
      <Select
        options={options}
        value={selectedBuilder ? { label: selectedBuilder, value: selectedBuilder } : null}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        isClearable
        placeholder="Select or type a builder"
        classNamePrefix="react-select"
      />
    </div>
  );
};
