import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Badge } from 'reactstrap';
import ErrorBoundary from './ErrorBoundary';
import { useFieldWork } from './DataHooks';
import moment from 'moment';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { getFields } from '../SystemSettings/StaticFieldLists';
import { getAddress, formatDate, formatDateTime } from '../../utils/utilFunctions';
import { toast } from 'react-toastify';
import { useAuth } from '../../auth/AuthProvider';
import { CSVLink } from 'react-csv';
import { debounce } from 'lodash';
import NewPotentialJob from './NewPotentialJob';
import DraftBuilderApprovalMessage from '../BuilderApprovals/DraftBuilderApprovalMessage';
import { generateBuilderSlug, generateBuilderMessage } from '../BuilderApprovals/BuilderSlug';

const UPDATE_CUSTOMTASKS = gql`
  mutation UpdateCustomTasks($tasks: [CustomTaskInput!]) {
    updateCustomTasks(tasks: $tasks) {
      id
    }
  }
`;

const UPDATE_JOB_STATUS = gql`
  mutation UpdateJobStatus($id: ID!, $status: String!) {
    updateJobStatus(id: $id, status: $status) {
      id
    }
  }
`;

const UPDATE_FIELD_COMMENT = gql`
  mutation UpdateFieldComment($fieldComment: FieldCommentInput!) {
    updateFieldComment(fieldComment: $fieldComment) {
      id
      scheduleId
      comments
      createdUtc
      user {
        id
        displayName
      }
    }
  }
`;

const DELETE_FIELD_COMMENT = gql`
  mutation DeleteFieldComment($id: ID!) {
    deleteFieldComment(id: $id)
  }
`;

const UPDATE_DEVELOPER_AND_SITE_CONTACT = gql`
  mutation UpdateDeveloperAndSiteContact(
    $id: ID!
    $developer: String!
    $siteContact: String!
    $siteContactPhone: String!
  ) {
    updateDeveloperAndSiteContact(
      id: $id
      developer: $developer
      siteContact: $siteContact
      siteContactPhone: $siteContactPhone
    )
  }
`;

const SEND_REQUEST = gql`
  mutation SendRequest($ids: [Int]!, $message: String!) {
    sendRequest(ids: $ids, message: $message)
  }
`;

const FieldWorkList = ({ settings, structuredAddressWorkTypes, groupId }) => {
  const auth = useAuth();
  const [customTaskList, setCustomTaskList] = useState();
  const [editCustomTask, setEditCustomTask] = useState(null);
  const [customDisplayNames, setCustomDisplayNames] = useState(null);
  const [optionalFieldWorkColumns, setOptionalFieldWorkColumns] = useState([]);
  const [optionalFieldWorkColumnCount, setOptionalFieldWorkColumnCount] = useState(0);
  const [fieldJobStatusSettings, setFieldJobStatusSettings] = useState([]);
  const [workTypesJobStatusOrder, setWorkTypesJobStatusOrder] = useState([]);
  const [showJobStatusCol, setShowJobStatusCol] = useState();
  const [searchFilter, setSearchFilter] = useState('');
  const [searchData, setSearchData] = useState('');
  const [editJobStatus, setEditJobStatus] = useState(null);
  const [editFieldComment, setEditFieldComment] = useState(null);
  // ToDo: The editing of developer and site contact has been combined on the one modal
  // but the system settings may have either one hidden and or editable.
  // A combined system setting should be created for this.
  const [canEditDeveloper, setCanEditDeveloper] = useState([]); // string array of workTypes
  const [canEditSiteContact, setCanEditSiteContact] = useState([]); // string array of workTypes
  const [builderList, setBuilderList] = useState([]);
  const [fieldWorkTasks, setFieldWorkTasks] = useState([]);
  const [columnSearchFilter, setColumnSearchFilter] = useState({});
  const [inputKey, setInputKey] = useState(0);
  const [showPotentialJobModal, setShowPotentialJobModal] = useState(false);
  const [editDeveloperAndContact, setEditDeveloperAndContact] = useState(null);
  const [selectedForBuilderApproval, setSelectedForBuilderApproval] = useState(null);

  const { fieldwork, loading } = useFieldWork(groupId, true);

  const [updateCustomTasks] = useMutation(UPDATE_CUSTOMTASKS);
  const [updateJobStatus] = useMutation(UPDATE_JOB_STATUS);
  const [updateDeveloperAndSiteContact] = useMutation(UPDATE_DEVELOPER_AND_SITE_CONTACT);
  const [updateFieldComment] = useMutation(UPDATE_FIELD_COMMENT);
  const [deleteFieldComment] = useMutation(DELETE_FIELD_COMMENT);
  const [sendRequestToBuilder] = useMutation(SEND_REQUEST);

  const taskGroupings = [
    { name: 'completed', title: 'Completed' },
    { name: 'previouslyScheduled', title: 'Previously Scheduled' },
    { name: 'monday', title: 'Monday', day: 1 },
    { name: 'tuesday', title: 'Tuesday', day: 2 },
    { name: 'wednesday', title: 'Wednesday', day: 3 },
    { name: 'thursday', title: 'Thursday', day: 4 },
    { name: 'friday', title: 'Friday', day: 5 },
    { name: 'saturday', title: 'Saturday', day: 6 },
    { name: 'sunday', title: 'Sunday', day: 0 },
    { name: 'byStatus', title: 'By Status' }
  ];

  const getDisplayStatus = (task) => {
    if (!task.acceptedDate && !task.completedDate) return 'New Job';
    if (!task.onsiteDate && !task.completedDate) return 'Accepted';
    if (!task.completedDate) return 'Work In Progress';
    return 'Completed';
  };

  const getShowJobStatus = (task) => {
    const fieldJobStatusSetting = fieldJobStatusSettings.find((x) => x.workType === task.workType);
    if (!fieldJobStatusSetting) return false;
    return true;
  };

  const filteredTasks = useMemo(() => {
    if (!fieldWorkTasks || !fieldwork?.customTasks) return {};

    let remainingTasks = fieldWorkTasks.filter((task) => task.status?.indexOf('On Hold') === -1);
    let remainingCustomTasks = fieldwork.customTasks.filter((task) => task.isCompleted === false);

    const searchFilterLower = searchFilter?.toLowerCase();

    // Apply the search filter
    if (searchFilterLower) {
      remainingTasks = remainingTasks.filter((task) => {
        const taskName = task.name?.toLowerCase();
        const taskAddress = structuredAddressWorkTypes?.includes(task.workType)
          ? getAddress(task)?.toLowerCase()
          : task.address?.toLowerCase();
        const taskWorkOrder = task.workOrder?.toLowerCase();
        const developer = task.developer?.toLowerCase();
        const siteContact = task.siteContact?.toLowerCase();
        const status = getDisplayStatus(task).toLowerCase();
        const showJobStatus = getShowJobStatus(task);
        const statusFilter = showJobStatus ? task.status?.toLowerCase() : '';

        return (
          taskName?.includes(searchFilterLower) ||
          taskAddress?.includes(searchFilterLower) ||
          taskWorkOrder?.includes(searchFilterLower) ||
          developer?.includes(searchFilterLower) ||
          siteContact?.includes(searchFilterLower) ||
          status?.includes(searchFilterLower) ||
          statusFilter?.includes(searchFilterLower)
        );
      });

      remainingCustomTasks = remainingCustomTasks.filter((task) => {
        const taskName = task.name?.toLowerCase();
        return taskName?.includes(searchFilterLower);
      });
    }

    // Apply the column search filter
    if (Object.keys(columnSearchFilter).length > 0) {
      remainingTasks = remainingTasks.filter((task) => {
        const taskName = task.name?.toLowerCase();
        const taskAddress = structuredAddressWorkTypes?.includes(task.workType)
          ? getAddress(task)?.toLowerCase()
          : task.address?.toLowerCase();
        const taskWorkOrder = task.workOrder?.toLowerCase();
        const developer = task.developer?.toLowerCase();
        const siteContact = task.siteContact?.toLowerCase();
        const status = getDisplayStatus(task).toLowerCase();
        const showJobStatus = getShowJobStatus(task);
        const statusFilter = showJobStatus ? task.status?.toLowerCase() : '';

        return (
          (!columnSearchFilter.task || taskName?.includes(columnSearchFilter.task.toLowerCase())) &&
          (!columnSearchFilter.address || taskAddress?.includes(columnSearchFilter.address.toLowerCase())) &&
          (!columnSearchFilter.workOrder || taskWorkOrder?.includes(columnSearchFilter.workOrder.toLowerCase())) &&
          (!columnSearchFilter.developer || developer?.includes(columnSearchFilter.developer.toLowerCase())) &&
          (!columnSearchFilter.siteContact || siteContact?.includes(columnSearchFilter.siteContact.toLowerCase())) &&
          (!columnSearchFilter.status || status?.includes(columnSearchFilter.status.toLowerCase())) &&
          (!columnSearchFilter.jobStatus || statusFilter?.includes(columnSearchFilter.jobStatus.toLowerCase())) &&
          (columnSearchFilter.onsiteDate === 'Yes'
            ? task.onsiteDate != null
            : columnSearchFilter.onsiteDate === 'No'
            ? task.onsiteDate == null
            : true)
        );
      });
      remainingCustomTasks = [];
    }

    let tasksByCategory = {
      completed: [],
      previouslyScheduled: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
      byStatus: [],
      nextWeek: [],
      nextMonth: [],
      onHold: []
    };

    const startOfWeek = moment().startOf('isoWeek').toDate(); // Monday as the start of the week
    const newEndOfWeek = moment().endOf('isoWeek').toDate(); // Sunday as the end of the week
    const nextWeekEnd = moment().add(1, 'week').endOf('isoWeek').toDate();

    const isInFieldJobStatusSettings = (task) =>
      fieldJobStatusSettings.some((settings) => settings.workType === task.workType);

    tasksByCategory.onHold = remainingTasks.filter((task) => task.status === 'On Hold');
    remainingTasks = remainingTasks.filter((task) => task.status !== 'On Hold');

    tasksByCategory.completed = remainingTasks.filter((task) => task.completedDate);
    remainingTasks = remainingTasks.filter((task) => !tasksByCategory.completed.includes(task));

    tasksByCategory.previouslyScheduled = remainingTasks.filter((task) => {
      const taskDate = moment.utc(task.scheduledStartTime).tz(moment.tz.guess());
      return taskDate < startOfWeek && task.status !== 'Complete' && !isInFieldJobStatusSettings(task);
    });
    remainingTasks = remainingTasks.filter((task) => !tasksByCategory.previouslyScheduled.includes(task));

    tasksByCategory.previouslyScheduled = tasksByCategory.previouslyScheduled.concat(
      remainingCustomTasks.filter((task) => {
        const taskDate = moment.utc(task.scheduledStartTime).tz(moment.tz.guess());
        return taskDate < startOfWeek;
      })
    );
    remainingCustomTasks = remainingCustomTasks.filter((task) => !tasksByCategory.previouslyScheduled.includes(task));

    const getTasksForDay = (dayOfWeek) => {
      const dayDate = moment()
        .startOf('isoWeek')
        .add(dayOfWeek === 0 ? 6 : dayOfWeek - 1, 'days')
        .toDate();
      const jobTasks = remainingTasks.filter((task) => {
        const taskDate = moment.utc(task.scheduledStartTime).tz(moment.tz.guess());
        return (
          taskDate.day() === dayOfWeek &&
          taskDate >= startOfWeek &&
          taskDate <= newEndOfWeek &&
          !isInFieldJobStatusSettings(task)
        );
      });

      const custTasks = remainingCustomTasks.filter((task) => {
        const taskDate = moment.utc(task.scheduledStartTime).tz(moment.tz.guess());
        return taskDate.day() === dayOfWeek && taskDate >= startOfWeek && taskDate <= newEndOfWeek;
      });

      return { tasks: jobTasks.concat(custTasks), date: dayDate };
    };

    taskGroupings.forEach((grouping) => {
      if (grouping.day !== undefined) {
        const data = getTasksForDay(grouping.day);
        tasksByCategory[grouping.name] = data.tasks;
        grouping.date = data.date;
      }
    });

    remainingTasks = remainingTasks.filter(
      (task) =>
        ![
          tasksByCategory.monday,
          tasksByCategory.tuesday,
          tasksByCategory.wednesday,
          tasksByCategory.thursday,
          tasksByCategory.friday,
          tasksByCategory.saturday,
          tasksByCategory.sunday,
          tasksByCategory.previouslyScheduled
        ]
          .flat()
          .includes(task)
    );

    remainingCustomTasks = remainingCustomTasks.filter(
      (task) =>
        ![
          tasksByCategory.monday,
          tasksByCategory.tuesday,
          tasksByCategory.wednesday,
          tasksByCategory.thursday,
          tasksByCategory.friday,
          tasksByCategory.saturday,
          tasksByCategory.sunday,
          tasksByCategory.previouslyScheduled
        ]
          .flat()
          .includes(task)
    );

    tasksByCategory.byStatus = remainingTasks.filter(
      (task) => isInFieldJobStatusSettings(task) && task.status !== 'Complete'
    );
    remainingTasks = remainingTasks.filter((task) => !tasksByCategory.byStatus.includes(task));

    tasksByCategory.nextWeek = remainingTasks.filter((task) => {
      const taskDate = moment.utc(task.scheduledStartTime).tz(moment.tz.guess());
      return taskDate > newEndOfWeek && taskDate <= nextWeekEnd;
    });

    const nextWeekCustomeTasks = remainingCustomTasks.filter((task) => {
      const taskDate = moment.utc(task.scheduledStartTime).tz(moment.tz.guess());
      return taskDate > newEndOfWeek && taskDate <= nextWeekEnd;
    });

    tasksByCategory.nextWeek = tasksByCategory.nextWeek.concat(nextWeekCustomeTasks);

    tasksByCategory.nextMonth = remainingTasks.filter((task) => new Date(task.scheduledStartTime) > nextWeekEnd);

    const nextMonthCustomTasks = remainingCustomTasks.filter((task) => new Date(task.scheduledStartTime) > nextWeekEnd);

    tasksByCategory.nextMonth = tasksByCategory.nextMonth.concat(nextMonthCustomTasks);

    return tasksByCategory;
  }, [fieldWorkTasks, searchFilter, fieldJobStatusSettings, structuredAddressWorkTypes, fieldwork, columnSearchFilter]);

  const headers = useMemo(() => {
    let baseHeaders = [
      { key: 'address', label: 'Address' },
      { key: 'task', label: 'Task' },
      { key: 'workOrder', label: 'Work Order' },
      { key: 'status', label: 'Status' }
    ];

    if (showJobStatusCol) {
      baseHeaders.push({ key: 'jobStatus', label: 'Job Status' });
    }

    const optionalHeaders = [
      {
        key: 'developer',
        label: customDisplayNames?.find((field) => field.fieldName === 'developer')?.displayName || 'Developer'
      },
      { key: 'scheduledStartTime', label: 'Scheduled Start' },
      { key: 'issuedDate', label: 'Issued Date' },
      { key: 'dueDate', label: 'Due Date' },
      { key: 'owner', label: 'Issued By' },
      { key: 'onsiteDate', label: 'Onsite Date' },
      { key: 'siteContact', label: 'Site Contact' }
    ];

    baseHeaders = baseHeaders.concat(optionalHeaders.filter((header) => optionalFieldWorkColumns.includes(header.key)));

    return baseHeaders;
  }, [showJobStatusCol, optionalFieldWorkColumns, customDisplayNames]);

  const excelHeaders = useMemo(() => {
    const fieldCommentHeader = { key: 'fieldComment', label: 'Field Comment' };
    const headersWithFieldComments = headers.concat(fieldCommentHeader);

    return headersWithFieldComments;
  }, [headers]);

  useEffect(() => {
    if (fieldwork) {
      const scrollPosition = sessionStorage.getItem('fieldworkListScrollPosition');
      if (scrollPosition) {
        setTimeout(() => {
          window.scrollTo(0, parseInt(scrollPosition, 10));
          sessionStorage.removeItem('fieldworkListScrollPosition');
        }, 400);
      }
    }
  }, [fieldwork]);

  useEffect(() => {
    const rejectedSchedules = Array.isArray(fieldwork.rejectedSchedules) ? fieldwork.rejectedSchedules : [];
    const workSchedule = Array.isArray(fieldwork.workSchedule) ? fieldwork.workSchedule : [];

    const workTypesInData = [...new Set([...rejectedSchedules, ...workSchedule].map((x) => x.workType))];

    const _customDisplayNames = [];
    settings.customDisplayNames.forEach((setting) => {
      if (workTypesInData.includes(setting.workType)) {
        const fields = JSON.parse(setting.config).fields;
        if (fields) {
          _customDisplayNames.push(...fields);
        }
      }
    });

    setCustomDisplayNames([...new Set(_customDisplayNames)]);

    let showJobStatus = false;
    const _fieldJobStatusSettings = [];
    settings.fieldJobStatusSettings.forEach((setting) => {
      const config = JSON.parse(setting.config);
      if (config?.useJobStatus) {
        if (workTypesInData.includes(setting.workType)) {
          _fieldJobStatusSettings.push({ config: config, workType: setting.workType });
          showJobStatus = true;
        }
      }
    });

    // Accumulate all visible fields based on work types in data
    const _optionalFieldWorkColumns = JSON.parse(JSON.stringify(getFields('Constructions').concat(getFields('Tasks'))))
      .filter((f) => f.isOptionalFieldWorkList)
      .map((f) => ({
        fieldName: f.fieldName,
        show: settings.optionalFieldWorkColumns.length === 0
      }));

    // manually add "siteContact" as we're concatenating name and number
    _optionalFieldWorkColumns.push({ fieldName: 'siteContact', show: false });

    const canEditDeveloperWorkTypes = [];
    const canEditSiteContactWorkTypes = [];

    settings.optionalFieldWorkColumns.forEach((setting) => {
      const config = JSON.parse(setting.config);

      if (!workTypesInData.includes(setting.workType)) return;

      const fields = config.fields;

      if (!fields) return;

      _optionalFieldWorkColumns.forEach((field) => {
        const storedField = fields.find((x) => x.fieldName === field.fieldName);

        if (storedField && storedField.show) {
          field.show = true;
        }

        if (storedField && storedField.fieldName === 'developer' && storedField.show && storedField.canEdit) {
          canEditDeveloperWorkTypes.push(setting.workType);
        }

        if (storedField && storedField.fieldName === 'siteContact' && storedField.show && storedField.canEdit) {
          canEditSiteContactWorkTypes.push(setting.workType);
        }
      });

      setCanEditDeveloper(canEditDeveloperWorkTypes);
      setCanEditSiteContact(canEditSiteContactWorkTypes);
    });

    setOptionalFieldWorkColumns(_optionalFieldWorkColumns.filter((x) => x.show).map((x) => x.fieldName));
    setOptionalFieldWorkColumnCount(_optionalFieldWorkColumns.filter((x) => x.show).length);

    const _workTypesJobStatusOrder = [];
    settings.jobStatusSettings.forEach((setting) => {
      const config = JSON.parse(setting.config);
      if (
        _fieldJobStatusSettings.some((fieldJobStatusSetting) => fieldJobStatusSetting.workType === setting.workType)
      ) {
        const statuses = config.statuses;
        const sortedStatuses = statuses.sort((a, b) => a.sortOrder - b.sortOrder);
        _workTypesJobStatusOrder.push({ workType: setting.workType, statuses: sortedStatuses });
      }
    });

    if (settings.lookups) {
      const builderLookup = settings.lookups.filter((lookup) => lookup.name === 'Builder Lookup')[0];
      if (builderLookup) {
        setBuilderList(builderLookup.data);
      }
    }

    setColumnSearchFilter({});
    setShowJobStatusCol(showJobStatus);
    setFieldJobStatusSettings(_fieldJobStatusSettings);
    setWorkTypesJobStatusOrder(_workTypesJobStatusOrder);
    setFieldWorkTasks(workSchedule);
  }, [fieldwork, settings]);

  useEffect(() => {
    if (fieldwork?.customTasks) {
      setCustomTaskList(fieldwork.customTasks);
    }
  }, [fieldwork.customTasks]);

  const SearchInput = ({ headerKey, setColumnSearchDataDebounced, defaultValue }) => {
    const [inputValue, setInputValue] = useState(defaultValue || '');
    const inputRef = useRef(null);

    const handleInputChange = (e) => {
      const newValue = e.target.value;
      setInputValue(newValue);

      setColumnSearchDataDebounced((prev) => {
        const newState = { ...prev };
        if (newValue.trim() === '') {
          delete newState[headerKey];
        } else {
          newState[headerKey] = newValue;
        }
        return newState;
      });
    };

    //ToDo: Work out how to keep focus when all text is deleted
    useEffect(() => {
      if (defaultValue) {
        inputRef.current.focus();
      }
    }, [defaultValue]);

    return (
      <input ref={inputRef} type="text" className="form-control" value={inputValue} onChange={handleInputChange} />
    );
  };

  const getDisplayName = (email) => {
    const user = settings.users.find((x) => x.email === email);
    return user?.displayName;
  };

  const isOverDue = (task) => {
    if (task.completedDate || !task.dueDate) {
      return false;
    }
    if (new Date(task.dueDate) < new Date()) {
      return true;
    }
    return false;
  };

  const handleEditCustomTask = () => {
    const customTask = customTaskList.find((x) => x.id === editCustomTask.id);
    if (customTask) {
      customTask.isCompleted = editCustomTask.isCompleted;
      setCustomTaskList([...customTaskList]);
      try {
        updateCustomTasks({
          variables: { tasks: customTaskList },
          operationName: 'UpdateCustomTasks'
        });
      } catch (error) {
        toast('Error Updating Custom Tasks', { type: 'error' });
      }
    }
    setEditCustomTask(null);
  };

  const getJobStatusColour = (task) => {
    let defaultReturn = task.completedDate ? '#cdedd8' : null; //default to green if completed

    const fieldJobStatusSetting = fieldJobStatusSettings.find((x) => x.workType === task.workType);
    if (!fieldJobStatusSetting) return defaultReturn;
    const fieldJobStatusColour = fieldJobStatusSetting.config.fieldJobStatusColours.find(
      (x) => x.statusName === task.status
    );
    if (!fieldJobStatusColour) return defaultReturn;
    return fieldJobStatusColour.colour;
  };

  const getStatusOptions = () => {
    const statusOptions =
      workTypesJobStatusOrder
        .find((x) => x.workType === editJobStatus.workType)
        ?.statuses.filter((x) => x.fieldEdit)
        .map((status) => status.statusName) || [];
    statusOptions.unshift(editJobStatus.status);

    const distinctStatusOptions = [...new Set(statusOptions)];

    return distinctStatusOptions.map((statusName) => (
      <option key={statusName} value={statusName}>
        {statusName}
      </option>
    ));
  };

  const getFieldComments = (task) => {
    const fieldComments = task.fieldComments
      ?.filter((x) => x.scheduleId === task.id && !!x.comments)
      .sort((a, b) => new Date(b.createdUtc) - new Date(a.createdUtc));
    return fieldComments;
  };

  const handleUpdateJobStatus = () => {
    try {
      updateJobStatus({
        variables: { id: parseInt(editJobStatus.constructionId), status: editJobStatus.status },
        operationName: 'UpdateJobStatus'
      });
      const task = filteredTasks.byStatus.find((x) => x.id === editJobStatus.id);
      if (task) {
        task.status = editJobStatus.status;
      }
    } catch (error) {
      toast('Error Updating Job Status', { type: 'error' });
    }
    setEditJobStatus(null);
  };

  const handleUpdateDeveloperAndContact = () => {
    try {
      updateDeveloperAndSiteContact({
        variables: {
          id: editDeveloperAndContact.constructionId,
          developer: editDeveloperAndContact.developer,
          siteContact: editDeveloperAndContact.siteContact,
          siteContactPhone: editDeveloperAndContact.siteContactPhone
        },
        operationName: 'UpdateDeveloperAndSiteContact'
      });
      const task = filteredTasks.byStatus.find((x) => x.id === editDeveloperAndContact.id);
      if (task) {
        task.developer = editDeveloperAndContact.developer;
        task.siteContact = editDeveloperAndContact.siteContact;
        task.siteContactPhone = editDeveloperAndContact.siteContactPhone;
      }
    } catch (error) {
      toast('Error Updating Developer and Site Contact', { type: 'error' });
    }
    setEditDeveloperAndContact(null);
  };

  const handleUpdateFieldComment = () => {
    try {
      const { createdUtc, user, ...fieldCommentToUpdate } = editFieldComment;
      const updateData = {
        id: fieldCommentToUpdate.id,
        userId: auth.me.id,
        scheduleId: fieldCommentToUpdate.scheduleId,
        comments: fieldCommentToUpdate.comments
      };

      updateFieldComment({
        variables: { fieldComment: updateData },
        operationName: 'UpdateFieldComment'
      }).then((response) => {
        const updatedComment = response.data.updateFieldComment;
        setFieldWorkTasks((prevFieldWorkTasks) => {
          return prevFieldWorkTasks.map((task) => {
            if (task.id === editFieldComment.scheduleId) {
              const index = task.fieldComments.findIndex((x) => x.id === updatedComment.id);
              let updatedFieldComments = [...task.fieldComments];

              if (index > -1) {
                updatedFieldComments[index] = {
                  ...updatedFieldComments[index],
                  comments: updatedComment.comments,
                  createdUtc: updatedComment.createdUtc.toString().replace('Z', ''),
                  userDisplayName: updatedComment.user.displayName,
                  userId: updatedComment.user.id
                };
              } else {
                updatedFieldComments = [
                  {
                    id: updatedComment.id,
                    scheduleId: updatedComment.scheduleId,
                    comments: updatedComment.comments,
                    createdUtc: updatedComment.createdUtc.toString().replace('Z', ''),
                    userDisplayName: updatedComment.user.displayName,
                    userId: updatedComment.user.id
                  },
                  ...updatedFieldComments
                ];
              }

              return { ...task, fieldComments: updatedFieldComments };
            }

            return task;
          });
        });
      });
    } catch (error) {
      toast('Error Updating Field Comment', { type: 'error' });
    }
    setEditFieldComment(null);
  };

  const handleDeleteFieldComment = (id) => {
    try {
      deleteFieldComment({
        variables: { id: id },
        operationName: 'DeleteFieldComment'
      }).then(() => {
        setFieldWorkTasks((prevFieldWorkTasks) => {
          return prevFieldWorkTasks.map((task) => {
            const index = task.fieldComments.findIndex((comment) => comment.id === id);
            if (index > -1) {
              const updatedFieldComments = [
                ...task.fieldComments.slice(0, index),
                ...task.fieldComments.slice(index + 1)
              ];
              return { ...task, fieldComments: updatedFieldComments };
            }
            return task;
          });
        });
      });
    } catch (error) {
      toast('Error Deleting Field Comment', { type: 'error' });
    }
    setEditFieldComment(null);
  };

  const handleTaskClick = () => {
    const scrollPosition = window.scrollY;
    sessionStorage.setItem('fieldworkListScrollPosition', scrollPosition);
  };

  const exportData = useMemo(() => {
    if (!filteredTasks) return [];
    const exportData = [];

    Object.keys(filteredTasks).forEach((key) => {
      if (Array.isArray(filteredTasks[key])) {
        filteredTasks[key].forEach((task) => {
          const row = {};
          excelHeaders.forEach((header) => {
            if (header.key === 'address') {
              row[header.key] = getAddress(task);
            } else if (header.key === 'task') {
              row[header.key] = task['name'];
            } else if (header.key === 'status') {
              row[header.key] = getDisplayStatus(task);
            } else if (header.key === 'jobStatus') {
              row[header.key] = getShowJobStatus(task) ? task['status'] : '';
            } else if (header.key === 'siteContact') {
              row[header.key] =
                task['siteContact'] + (task['siteContactPhone'] ? ` (${task['siteContactPhone']})` : '');
            } else if (header.key === 'fieldComment') {
              const fieldComments = getFieldComments(task);
              if (fieldComments && fieldComments.length > 0) {
                row[header.key] = fieldComments[0].comments;
              } else {
                row[header.key] = '';
              }
            } else {
              if ((header.key.includes('Date') || header.key.includes('Time')) && task[header.key]) {
                row[header.key] = task[header.key].substring(0, 10);
              } else {
                row[header.key] = task[header.key];
              }
            }
          });

          exportData.push(row);
        });
      }
    });

    return exportData;
  }, [filteredTasks, excelHeaders]);

  const uniqueSelectOptions = useMemo(() => {
    const uniqueOptions = {
      task: [...new Set(fieldWorkTasks.map((task) => task.name).filter((name) => name != null && name !== ''))].sort(),
      status: [...new Set(fieldWorkTasks.map((task) => getDisplayStatus(task)))]
    };

    if (optionalFieldWorkColumns.includes('developer')) {
      uniqueOptions.developer = [
        ...new Set(
          fieldWorkTasks.map((task) => task.developer).filter((developer) => developer != null && developer !== '')
        )
      ].sort();
    }

    if (optionalFieldWorkColumns.includes('owner')) {
      uniqueOptions.owner = [
        ...new Set(
          fieldWorkTasks.map((task) => getDisplayName(task.owner)).filter((owner) => owner != null && owner !== '')
        )
      ].sort();
    }

    if (optionalFieldWorkColumns.includes('onsiteDate')) {
      uniqueOptions.onsiteDate = ['Yes', 'No'];
    }

    if (showJobStatusCol) {
      uniqueOptions.jobStatus = [
        ...new Set(
          fieldWorkTasks
            .map((task) => {
              if (getShowJobStatus(task)) {
                return task.status;
              }
              return null;
            })
            .filter((jobStatus) => jobStatus != null && jobStatus !== '')
        )
      ].sort();
    }

    return uniqueOptions;
  }, [fieldWorkTasks, optionalFieldWorkColumns]);

  const groupedDisplayTasks = (grouping) => {
    const displayTasks = filteredTasks[grouping.name];

    if (grouping.name !== 'byStatus') {
      return [...displayTasks]
        .sort((a, b) => new Date(a.scheduledStartTime) - new Date(b.scheduledStartTime))
        .sort((a, b) => (a.isCurrentlyOnsite === b.isCurrentlyOnsite ? 0 : a.isCurrentlyOnsite ? -1 : 1));
    }

    displayTasks.sort((a, b) => {
      if (a.isCurrentlyOnsite !== b.isCurrentlyOnsite) {
        return a.isCurrentlyOnsite ? -1 : 1;
      }

      if (a.workType !== b.workType) {
        return a.workType.localeCompare(b.workType);
      }

      const aWorkType = workTypesJobStatusOrder.find((x) => x.workType === a.workType);
      const bWorkType = workTypesJobStatusOrder.find((x) => x.workType === b.workType);

      const aStatusOrder = aWorkType?.statuses.find((x) => x.statusName === a.status)?.fieldOrder || 0;
      const bStatusOrder = bWorkType?.statuses.find((x) => x.statusName === b.status)?.fieldOrder || 0;

      if (aStatusOrder !== bStatusOrder) {
        return aStatusOrder - bStatusOrder;
      }

      return new Date(a.scheduledStartTime) - new Date(b.scheduledStartTime);
    });

    return displayTasks;
  };

  const CurrentTasksRows = ({ grouping }) => {
    const displayTasks = groupedDisplayTasks(grouping);

    return (
      <>
        {displayTasks.length > 0 && (
          <tr key={grouping.name}>
            <td colSpan={optionalFieldWorkColumnCount + 5}>
              <h5>{grouping.title + (grouping.date !== undefined ? ' - ' + formatDate(grouping.date) : '')}</h5>
            </td>
          </tr>
        )}
        <TaskRows displayTasks={displayTasks} groupingName={grouping.name} />
      </>
    );
  };

  const setColumnSearchDebounced = useMemo(() => {
    return debounce((updateFunction) => {
      setColumnSearchFilter(updateFunction);
    }, 800);
  }, [setColumnSearchFilter]);

  // #region Builder Approval

  const handleSelectForBuilderApproval = (task) => {
    const fullAddress = getAddress(task);
    const jobIds = [task.constructionId];

    const { link, domainName } = generateBuilderSlug(task.siteContact, task.siteContactPhone, jobIds);

    setSelectedForBuilderApproval({
      ...selectedForBuilderApproval,
      jobs: [{ ...task, address: fullAddress, selected: true }],
      addressText: fullAddress,
      message: generateBuilderMessage(fullAddress, link, domainName),
      siteContact: task.siteContact,
      phoneNumber: task.siteContactPhone
    });
  };

  const handleCloseApproval = () => {
    setSelectedForBuilderApproval(null);
  };

  const requestSent = () => {
    const jobIds = selectedForBuilderApproval.jobs.map((job) => job.constructionId);
    const message = selectedForBuilderApproval.message.replace(/\n/g, ' ');
    sendRequestToBuilder({
      variables: { ids: jobIds, message: message }
    }).then(() => {
      // Update the local state to reflect the builder approval sent date
      setFieldWorkTasks((prevFieldWorkTasks) => {
        return prevFieldWorkTasks.map((task) => {
          if (jobIds.includes(task.constructionId)) {
            return {
              ...task,
              builderApprovalSentDate: new Date().toISOString()
            };
          }
          return task;
        });
      });
      setSelectedForBuilderApproval(null);
    });
  };

  // #endregion

  const SearchRow = () => {
    const textsearch = ['address', 'workOrder', 'siteContact'];
    return (
      <tr>
        {headers.map((header) => {
          const isSelectField = uniqueSelectOptions.hasOwnProperty(header.key);
          if (isSelectField || header.key === 'jobStatus') {
            return (
              <td key={header.key}>
                <select
                  className="form-control"
                  defaultValue={columnSearchFilter[header.key] || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    setColumnSearchFilter((prev) => {
                      const newState = { ...prev };
                      if (value === '') {
                        delete newState[header.key];
                      } else {
                        newState[header.key] = value;
                      }
                      return newState;
                    });
                  }}
                >
                  <option value="">All</option>
                  {uniqueSelectOptions[header.key]?.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </td>
            );
          } else if (textsearch.includes(header.key)) {
            return (
              <td key={header.key}>
                <SearchInput
                  headerKey={header.key}
                  setColumnSearchDataDebounced={setColumnSearchDebounced}
                  defaultValue={columnSearchFilter[header.key]}
                />
              </td>
            );
          }
          return <td key={header.key}></td>;
        })}
      </tr>
    );
  };

  const TaskRows = ({ displayTasks, groupingName = '' }) => {
    return (
      <>
        {displayTasks.map((task) => {
          const key = task.isCompletable ? 'c_' + task.id : 't_' + task.id;
          return (
            <React.Fragment key={key}>
              {task.isCompletable ? (
                <tr key={task.id} style={{ backgroundColor: '#F0E3FA' }}>
                  <td
                    colSpan={
                      optionalFieldWorkColumns.includes('developer')
                        ? showJobStatusCol
                          ? 6
                          : 5
                        : showJobStatusCol
                        ? 5
                        : 4
                    }
                    onClick={() => setEditCustomTask(task)}
                  >
                    {task.name}
                  </td>
                  {optionalFieldWorkColumns.includes('scheduledStartTime') && (
                    <td>{formatDate(task.scheduledStartTime)}</td>
                  )}
                </tr>
              ) : (
                <>
                  <tr style={{ backgroundColor: getJobStatusColour(task) }}>
                    <td>
                      {structuredAddressWorkTypes?.includes(task.workType) ? getAddress(task) : <>{task.address}</>}
                      {task.isCurrentlyOnsite && <Badge className="bg-success ml-1">Onsite</Badge>}
                    </td>
                    <td>
                      <Link onClick={handleTaskClick} to={`/field/construction/${task.constructionId}/${task.id}`}>
                        {task.name}
                      </Link>
                    </td>
                    <td>{task.workOrder}</td>
                    <td>{getDisplayStatus(task)}</td>
                    {showJobStatusCol && (
                      <td>
                        {getShowJobStatus(task) && (
                          <span onClick={() => setEditJobStatus(task)} style={{ cursor: 'pointer' }}>
                            {workTypesJobStatusOrder
                              .find((x) => x.workType === task.workType)
                              ?.statuses.filter((x) => x.fieldEdit).length > 0 && <i className="icon-pencil" />}{' '}
                            {task.status}
                          </span>
                        )}
                      </td>
                    )}
                    {optionalFieldWorkColumns.includes('developer') && (
                      <td>
                        <>
                          {canEditDeveloper.includes(task.workType) ? (
                            <span onClick={() => setEditDeveloperAndContact(task)} style={{ cursor: 'pointer' }}>
                              <i title="Edit Developer" className="icon-pencil" /> {task.developer}
                            </span>
                          ) : (
                            task.developer
                          )}
                        </>
                      </td>
                    )}
                    {optionalFieldWorkColumns.includes('scheduledStartTime') && (
                      <td>{formatDate(task.scheduledStartTime)}</td>
                    )}
                    {optionalFieldWorkColumns.includes('issuedDate') && <td>{formatDate(task.issuedDate)}</td>}
                    {optionalFieldWorkColumns.includes('dueDate') && (
                      <td className={isOverDue(task) ? 'bg-danger' : ''}>{formatDate(task.dueDate)}</td>
                    )}
                    {optionalFieldWorkColumns.includes('owner') && <td>{getDisplayName(task.owner)}</td>}
                    {optionalFieldWorkColumns.includes('onsiteDate') && <td>{formatDate(task.onsiteDate)}</td>}
                    {optionalFieldWorkColumns.includes('siteContact') && (
                      <td>
                        <>
                          {canEditSiteContact.includes(task.workType) ? (
                            <span onClick={() => setEditDeveloperAndContact(task)} style={{ cursor: 'pointer' }}>
                              <i title="Edit Site Contact" className="icon-pencil" /> {task.siteContact}
                              {task.siteContactPhone ? ` (${task.siteContactPhone})` : ''}
                            </span>
                          ) : (
                            <>
                              {task.siteContact}
                              {task.siteContactPhone ? ` (${task.siteContactPhone})` : ''}
                            </>
                          )}
                        </>
                      </td>
                    )}
                  </tr>
                  {task.comments && (
                    <tr style={{ backgroundColor: '#FFFDF0' }}>
                      <td colSpan={'100%'}>
                        <div className="pl-4">
                          <strong>Office Comment: </strong>
                          {task.comments}
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="100%">
                      <div className="d-flex justify-content-between align-items-center p-1">
                        <div className="d-flex align-items-center">
                          <Button
                            onClick={() =>
                              setEditFieldComment({
                                ...editFieldComment,
                                scheduleId: task.id,
                                groupingName: groupingName
                              })
                            }
                            size="sm"
                            className="mr-2"
                          >
                            Add Field Comment
                          </Button>
                          {/* Show Builder Approval button or status based on priority */}
                          {task.builderApprovedDate ? (
                            <div className="alert alert-success mb-0 px-2 py-1">
                              Builder Approved: {formatDate(task.builderApprovedDate)}
                            </div>
                          ) : task.builderApprovalSentDate ? (
                            <div className="alert alert-info mb-0 px-2 py-1">
                              Builder Approval Sent: {formatDate(task.builderApprovalSentDate)}
                            </div>
                          ) : (
                            task.streetName &&
                            task.suburb &&
                            task.siteContact &&
                            task.siteContactPhone &&
                            task.developer && (
                              <Button size="sm" color="warning" onClick={() => handleSelectForBuilderApproval(task)}>
                                Builder Approval
                              </Button>
                            )
                          )}
                        </div>
                        <div className="d-flex align-items-center">
                          {task.attachmentCount > 0 && <Badge color="info">{task.attachmentCount} Attachments</Badge>}
                        </div>
                      </div>
                    </td>
                  </tr>

                  {getFieldComments(task)?.map((fieldComment) => {
                    const isCommentOwner = auth.me.id === fieldComment.userId;

                    return (
                      <tr key={fieldComment.id} style={{ backgroundColor: '#FFFDF0' }}>
                        <td colSpan="100%">
                          <div className="pl-4">
                            <Button
                              onClick={() =>
                                isCommentOwner && setEditFieldComment({ ...fieldComment, groupingName: groupingName })
                              }
                              style={{
                                background: 'none',
                                border: 'none',
                                color: 'black',
                                padding: 0,
                                margin: 0,
                                textDecoration: 'none',
                                boxShadow: 'none',
                                cursor: isCommentOwner ? 'pointer' : 'default'
                              }}
                              className="p-0 text-left font-weight-bold"
                              disabled={!isCommentOwner}
                            >
                              {isCommentOwner && <i className="icon-pencil" />}{' '}
                              {formatDateTime(fieldComment.createdUtc) + ': '}
                            </Button>{' '}
                            {fieldComment.comments}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const ScheduleTableHeaders = ({ isCurrent = false }) => {
    return (
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header.key}>{header.label}</th>
          ))}
        </tr>
        {isCurrent && <SearchRow />}
      </thead>
    );
  };

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <>
      <div className="d-flex flex-wrap w-100">
        <div className="pr-2 pb-1" style={{ minWidth: '170px' }}>
          <input
            key={inputKey}
            className="form-control"
            type="text"
            name="search"
            placeholder="Search"
            defaultValue={searchFilter}
            onBlur={(e) => setSearchData(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setSearchFilter(e.target.value);
              }
            }}
          />
        </div>
        <div className="pr-2 pb-1">
          <button
            className="btn btn-primary"
            onClick={() => {
              setSearchFilter(searchData);
            }}
          >
            Search
          </button>
        </div>
        <div className="pr-2 pb-1">
          {filteredTasks && exportData && (
            <CSVLink
              headers={excelHeaders}
              className="btn btn-primary"
              target="_blank"
              filename={'FiledList' + '.csv'}
              data={exportData}
            >
              Export
            </CSVLink>
          )}
        </div>
        <div className="pb-1">
          <Button
            color="success"
            onClick={() => {
              setShowPotentialJobModal(true);
            }}
          >
            Add Potential Job
          </Button>
        </div>
        <div className="ml-auto pb-1">
          <button
            className={`btn ${
              Object.keys(columnSearchFilter).length > 0 || searchFilter !== '' ? 'btn-warning' : 'btn-secondary'
            } ml-2`}
            onClick={() => {
              setColumnSearchFilter({});
              setSearchFilter('');
              setInputKey((prevKey) => prevKey + 1);
            }}
            disabled={Object.keys(columnSearchFilter).length === 0 && searchFilter === ''}
          >
            Clear Filters
          </button>
        </div>
      </div>
      {structuredAddressWorkTypes && fieldwork.rejectedSchedules && fieldwork.rejectedSchedules.length > 0 && (
        <div className="card border border-danger">
          <div className="card-header bg-danger">
            <h5 className="mb-0">Please correct item quantities for the following tasks</h5>
          </div>
          <Table hover style={{ width: '100%' }} responsive>
            <ScheduleTableHeaders />
            <tbody>
              <TaskRows displayTasks={fieldwork.rejectedSchedules} />
            </tbody>
          </Table>
        </div>
      )}
      {filteredTasks && Object.keys(filteredTasks).length > 0 && (
        <>
          <div className="card card-body">
            <h5>Current</h5>
            <Table hover style={{ width: '100%' }} responsive>
              <ScheduleTableHeaders isCurrent={true} />
              <tbody>
                {taskGroupings.map((grouping) => (
                  <CurrentTasksRows key={grouping.name} grouping={grouping} />
                ))}
              </tbody>
            </Table>
          </div>
          {filteredTasks.nextWeek.length > 0 && (
            <div className="card card-body">
              <h5>Next Week</h5>
              <Table hover style={{ width: '100%' }} responsive>
                <ScheduleTableHeaders />
                <tbody>
                  <TaskRows displayTasks={filteredTasks.nextWeek} groupingName="nextWeek" />
                </tbody>
              </Table>
            </div>
          )}
          {filteredTasks.nextMonth.length > 0 && (
            <div className="card card-body">
              <h5>Next Month</h5>
              <Table hover style={{ width: '100%' }} responsive>
                <ScheduleTableHeaders />
                <tbody>
                  <TaskRows displayTasks={filteredTasks.nextMonth} groupingName="nextMonth" />
                </tbody>
              </Table>
            </div>
          )}
          {filteredTasks.onHold.length > 0 && (
            <div className="card card-body">
              <h5>On Hold</h5>
              <Table hover style={{ width: '100%' }} responsive>
                <ScheduleTableHeaders />
                <tbody>
                  <TaskRows displayTasks={filteredTasks.onHold} groupingName="onHold" />
                </tbody>
              </Table>
            </div>
          )}
        </>
      )}
      {editCustomTask && (
        <Modal isOpen={editCustomTask !== null} backdrop={true} toggle={() => setEditCustomTask(null)}>
          <ModalHeader>Edit Custom Task</ModalHeader>
          <ModalBody>
            <div>{editCustomTask.name}</div>
            <hr />
            <div>
              <input
                type="checkbox"
                checked={editCustomTask.isCompleted}
                onChange={(e) => setEditCustomTask({ ...editCustomTask, isCompleted: e.target.checked })}
              />{' '}
              <label className="form-check-label ml-1" htmlFor="isCompleted">
                Completed
              </label>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={() => handleEditCustomTask()}>
              Done
            </button>
          </ModalFooter>
        </Modal>
      )}
      {editJobStatus && (
        <Modal isOpen={editJobStatus !== null} backdrop={true} toggle={() => setEditJobStatus(null)}>
          <ModalHeader>Update Job Status</ModalHeader>
          <ModalBody>
            <div>
              {structuredAddressWorkTypes?.includes(editJobStatus.workType) ? (
                getAddress(editJobStatus)
              ) : (
                <>{editJobStatus.address}</>
              )}
            </div>
            <hr />
            <div>
              <select
                className="form-control"
                defaultValue={editJobStatus.status}
                onBlur={(e) => setEditJobStatus({ ...editJobStatus, status: e.target.value })}
              >
                {getStatusOptions()}
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={() => setEditJobStatus(null)}>
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                handleUpdateJobStatus();
              }}
            >
              Update
            </button>
          </ModalFooter>
        </Modal>
      )}
      {editDeveloperAndContact && (
        <Modal
          isOpen={editDeveloperAndContact !== null}
          backdrop={true}
          toggle={() => setEditDeveloperAndContact(null)}
        >
          <ModalHeader>Edit Developer and Site Contact</ModalHeader>
          <ModalBody>
            <div>
              {structuredAddressWorkTypes?.includes(editDeveloperAndContact.workType) ? (
                getAddress(editDeveloperAndContact)
              ) : (
                <>{editDeveloperAndContact.address}</>
              )}
            </div>
            <hr />
            {canEditDeveloper.includes(editDeveloperAndContact.workType) && (
              <div className="py-2">
                <label>Developer</label>
                <select
                  className="form-control"
                  value={editDeveloperAndContact.developer || ''}
                  onChange={(e) =>
                    setEditDeveloperAndContact({
                      ...editDeveloperAndContact,
                      developer: e.target.value
                    })
                  }
                >
                  <option value="">Select Option...</option>
                  {builderList.map((builder) => (
                    <option key={builder} value={builder}>
                      {builder}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {canEditSiteContact.includes(editDeveloperAndContact.workType) && (
              <>
                <div className="py-2">
                  <label>Site Contact Name</label>
                  <input
                    className="form-control"
                    defaultValue={editDeveloperAndContact.siteContact}
                    onBlur={(e) =>
                      setEditDeveloperAndContact({
                        ...editDeveloperAndContact,
                        siteContact: e.target.value
                      })
                    }
                  />
                </div>
                <div className="py-2">
                  <label>Site Contact Phone</label>
                  <input
                    className="form-control"
                    defaultValue={editDeveloperAndContact.siteContactPhone}
                    onBlur={(e) =>
                      setEditDeveloperAndContact({
                        ...editDeveloperAndContact,
                        siteContactPhone: e.target.value
                      })
                    }
                  />
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={() => setEditDeveloperAndContact(null)}>
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                handleUpdateDeveloperAndContact();
              }}
            >
              Update
            </button>
          </ModalFooter>
        </Modal>
      )}
      {editFieldComment && (
        <Modal isOpen={editFieldComment !== null} backdrop={true} toggle={() => setEditFieldComment(null)}>
          <ModalHeader>Edit Field Comment</ModalHeader>
          <ModalBody>
            <div>
              <textarea
                className="form-control"
                defaultValue={editFieldComment.comments}
                onBlur={(e) =>
                  setEditFieldComment({
                    ...editFieldComment,
                    comments: e.target.value
                  })
                }
              />
            </div>
          </ModalBody>
          <ModalFooter>
            {editFieldComment.id && (
              <Button
                color="danger"
                onClick={() => {
                  handleDeleteFieldComment(editFieldComment.id);
                }}
                className="mr-auto"
              >
                Delete
              </Button>
            )}
            <Button color="secondary" onClick={() => setEditFieldComment(null)}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleUpdateFieldComment();
              }}
            >
              Update
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {showPotentialJobModal && (
        <NewPotentialJob
          showPotentialJobModal={showPotentialJobModal}
          setShowPotentialJobModal={setShowPotentialJobModal}
        />
      )}
      {selectedForBuilderApproval && (
        <DraftBuilderApprovalMessage
          selectedJobsData={selectedForBuilderApproval}
          requestSent={requestSent}
          handleCloseApproval={handleCloseApproval}
        />
      )}
    </>
  );
};

const WrappedFieldWorkList = (props) => {
  return (
    <ErrorBoundary>
      <FieldWorkList {...props} />
    </ErrorBoundary>
  );
};

export default WrappedFieldWorkList;
